import { Base64 } from "js-base64";
/**
 * 全局配置文件
 */
export default {
  key: "ssq",
  clientId: Base64.decode(process.env.VUE_APP_ECLKER_KEY), // 客户端id
  clientSecret: Base64.decode(process.env.VUE_APP_ECLKER_SECRET), // 客户端密钥
  sm4Key: Base64.decode(process.env.VUE_APP_KEY),
  appid: Base64.decode(process.env.VUE_APP_APPID)
};
