import request from "@/router/axios";

export const addUser = (data) => {
  return request({
    url: "/api/blade-auth/wxauth/register",
    method: "post",
    headers: {
      "Content-Type": "application/json",
    },
    data,
  });
};
export const unbindPhone = (data) => {
  return request({
    url: "api/blade-auth/wxauth/unbind/" + data,
    method: "delete"
  });
};
export const getAuthCode = (params) => {
  return request({
    url: "/api/blade-auth/wxauth/getAuthCode",
    method: "get",
    params,
  });
};
