import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);

const routes = [
  {
    path: "/",
    redirect: "/index"
  },
  {
    path: "/index",
    name: "微信授权",
    component: () =>
      import(/* webpackChunkName: "views" */ "@/views/index/index"),
    meta: {
      keepAlive: true,
      isTab: false,
      isAuth: false
    }
  },
  {
    path: "/certification",
    name: "身份认证",
    component: () =>
      import(/* webpackChunkName: "views" */ "@/views/certification/index"),
    meta: {
      keepAlive: true,
      isTab: false,
      isAuth: false
    }
  },
  {
    path: "/interests",
    name: "权益单",
    component: () =>
      import(/* webpackChunkName: "views" */ "@/views/interests/index"),
    meta: {
      keepAlive: true,
      isTab: false,
      isAuth: false
    }
  },
  {
    path: "/protocol",
    name: "用户服务协议",
    component: () =>
      import(/* webpackChunkName: "views" */ "@/views/protocol/index"),
    meta: {
      keepAlive: true,
      isTab: false,
      isAuth: false
    }
  },
  {
    path: "/policy",
    name: "隐私政策",
    component: () =>
      import(/* webpackChunkName: "views" */ "@/views/policy/index"),
    meta: {
      keepAlive: true,
      isTab: false,
      isAuth: false
    }
  }
];

export default new Router({
  routes
});
