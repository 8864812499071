import request from '@/router/axios';

export const getUserInfo = (openId) => {
  return request({
    url: '/api/blade-auth/wxauth/userInfo/'+ openId,
    method: 'get'
  })
}
export const getOpenid = (code) => {
  return request({
    url: '/api/blade-auth/wxauth/getOpenIdByCode/'+ code,
    method: 'get'
  })
}
