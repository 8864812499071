import Vue from "vue";
import Vuex from "vuex";
import { Base64 } from "js-base64";
import sm4 from "~/util/sm4.js";
import { deepClone } from "~/util/util.js";
import { setStore, getStore, clearStore } from "~/util/store";
import { addUser } from "@/api/certification/index.js";
import { getOpenid } from "@/api/index/index.js";
import website from "~/config/website";

Vue.use(Vuex);
const store = new Vuex.Store({
  state: {
    openid: getStore({ name: "openId" }) || "",
    faced: getStore({ name: "faced" }) || false,
    userInfo: getStore({ name: "userInfo" }) || "",
    website
  },
  actions: {
    register({ commit }, sendData) {
      return new Promise((resolve, reject) => {
        addUser(sendData)
          .then(() => {
            commit("CHANGE_NOFACED", true);
            resolve(true);
          })
          .catch(e => {
            reject(e);
          });
      });
    },
    getOpenid({ commit }, code) {
      return new Promise((resolve, reject) => {
        getOpenid(code).then(res => {
          if(res.data.code == 200) {
            const openid = res.data.data.openid;
            commit("ADD_OPENID", Base64.encode(openid));
            resolve(true);
            return;
          }
          resolve(false);
        }).catch(e => {
          reject(e);
        });
      });
    }
  },
  mutations: {
    ADD_OPENID: (state, openid) => {
      state.openid = openid;
      setStore({
        name: "openId",
        content: state.openid
      });
    },
    CHANGE_NOFACED: (state, faced) => {
      state.faced = faced;
      setStore({
        name: "faced",
        content: state.faced
      });
    },
    ADD_USERINFO: (state, info) => {
      info = deepClone(info);
      const keys = Object.keys(info);
      const key = website.sm4Key;
      // 不需要加密的字段
      const noEncrypt = ['authCode', 'bizToken'];
      keys.forEach(k => {
        if(noEncrypt.indexOf(k) == -1) {
          info[k] = sm4.encrypt(info[k], key);
        }
      });
      state.info = info;
      setStore({
        name: "userInfo",
        content: state.info
      });
    },
    CLEAR: () => {
      clearStore();
    }
  },
  getters: {
    openid: state => Base64.decode(state.openid),
    faced: state => state.faced,
    userInfo: state => state.userInfo,
    website: state => state.website
  }
});

export default store;
