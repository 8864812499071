/**
 * 全站权限配置
 */
import router from "@/router/index";
import store from "@/store";
import NProgress from "nprogress"; // progress bar
import "nprogress/nprogress.css"; // progress bar style
NProgress.configure({ showSpinner: false });
router.beforeEach((to, from, next) => {
  const path = to.path;
  let openid = store.getters.openid;
  let faced = store.getters.faced;
  if (path == "/" || path == "/index") {
    store.commit("CLEAR");
    next();
  } 
  else {
    // 没有openid，则跳微信授权
    if (!openid) {
      next("/index");
    }
    else {
      next();
    }
  }
});

router.afterEach(() => {});
